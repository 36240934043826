<template>
  <v-container fluid>
    <v-card
      tile
    >
      <v-tabs
        v-model="tabsModel"
        color="primary"
      >
        <v-tab
          key="tabSingle"
          :href="'#tab-single-create'"
        >
          {{ $vuetify.lang.t('$vuetify.common.create') }}
        </v-tab>

        <v-tab
          key="tabSingleSign"
          :href="'#tab-single-sign'"
        >
          {{ $vuetify.lang.t('$vuetify.patient.createSign') }}
        </v-tab>

        <v-tab
          key="tabBatch"
          :href="'#tab-batch-create'"
        >
          {{ $vuetify.lang.t('$vuetify.common.batchCreate') }}
        </v-tab>

        <v-tab
          key="tabBatchSign"
          :href="'#tab-batch-sign'"
        >
          {{ $vuetify.lang.t('$vuetify.patient.batchCreateSign') }}
        </v-tab>

        <v-tab-item
          key="store"
          :value="'tab-single-create'"
        >
          <v-card>
            <v-card-text>
              <v-container>
                <base-form
                  @submit="handleSubmit"
                  @cancel="handleCancel"
                />
              </v-container>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item
          key="createSign"
          :value="'tab-single-sign'"
        >

          <v-container
            key="equForm"
            fluid
          >
            <create-sign />
          </v-container>
        </v-tab-item>

        <v-tab-item
          key="batchImport"
          :value="'tab-batch-create'"
        >
          <v-card>
            <v-card-text>
              <v-container>
                <batch-form
                  @submit="handleBatchFormSubmit"
                  @cancel="handleCancel"
                />
                </v-container>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item
          key="batchCreateSign"
          :value="'tab-batch-sign'"
        >

          <v-container
            key="equForm"
            fluid
          >
            <batch-import-sign />
          </v-container>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </v-container>
</template>

<script>

  import { mapActions } from 'vuex';
  import dialogs from '@/services/dialogs.js';
  import { getLocalMessage } from '@/utils';

  export default {
    components: {
      BaseForm: () => import('./BaseForm'),
      BatchForm: () => import('./BatchForm'),
      CreateSign: () => import('./CreateSign'),
      BatchImportSign: () => import('./BatchImportSign')
    },
    data: () => ({
      tabsModel: 'tab-single-create'
    }),

    created () {
      if (this.$route.params.patch) {
        this.tabsModel = this.$route.params.patch
      }
    },

    methods: {
      ...mapActions({
        patientCreate: 'patient/create',
        downloadTemplate: 'equipment/downloadTemplate',
        importById: 'equipment/importById',
        patientImportCreate: 'patient/importCreate'
      }),

      getLocalMessage,

      handleCancel () {
        this.$router.go(-1)
      },

      handleSubmit (data) {
        this.patientCreate(data).then( () => {
          this.$router.push({name: 'patientList'})
        }).catch( error => {
          if (error.data && error.data.code == 1100) {
            let messages = error.data.message.split('\'')
            dialogs.message(this.$vuetify.lang.t('$vuetify.message.resourceExistedErr', [this.getLocalMessage(this, 'patient', messages[1])]), { duration: 10, type: 'error' })
          } else {
            dialogs.message(this.$vuetify.lang.t('$vuetify.message.systemErr'), { duration: 10, type: 'error' })
          }
        })
      },

      handleBatchFormSubmit (data) {
        const params = {}
        for (let key in data) {
          if (key !== 'uploadFile') {
            params[key] = data[key]
          }
        }
        const formData = new FormData()
        formData.append('file', data.uploadFile)
        this.patientImportCreate({ params: params , data: formData}).then( (data) => {
          if (data.data.successNum === 0) {
            dialogs.message(this.$vuetify.lang.t('$vuetify.message.importTooltips', data.data.successNum, data.data.failedNum), { duration: 10, type: 'error' })
          } else if (data.data.failedNum === 0) {
            dialogs.message(this.$vuetify.lang.t('$vuetify.message.importTooltips', data.data.successNum, data.data.failedNum), { duration: 10, type: 'success' }, () => this.$router.push( { name: 'patientList' }))
          } else {
            dialogs.message(this.$vuetify.lang.t('$vuetify.message.importTooltips', data.data.successNum, data.data.failedNum), { duration: 10, type: 'warning' })
          }
        }).catch( () => {
          dialogs.message(this.$vuetify.lang.t('$vuetify.message.systemErr'), { duration: 10, type: 'error' })
        })
      }
    },
  }
</script>
